import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/MoveToInbox'
import { RootState } from 'app/store'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toggleNavbar } from 'theme/uiSlice'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

export default function Sidebar() {
    const dispatch = useDispatch()

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const { navbarOpen } = useSelector((state: RootState) => state.ui)
    const { user } = useSelector((state: RootState) => state.oidc)

    const onToggleNavBar = () => {
        dispatch(toggleNavbar())
    }

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'persistent'}
            anchor="left"
            open={navbarOpen}
            onClose={onToggleNavBar}
            classes={{
                paper: clsx(classes.drawerPaper, !navbarOpen && classes.drawerPaperClose),
            }}>
            <div className={classes.appBarSpacer} />
            <Divider />
            <Box p={1} textAlign="center">
                <Box mx="auto" clone>
                    <Avatar alt="user avatar" src="/logo192.png" className={clsx(classes.large)} />
                </Box>
                <p>{user?.profile.name}</p>
                <p>{user?.profile.email}</p>
            </Box>
            <Divider />
            <List>
                <ListItem button component={Link} to="/home">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component={Link} to="/role">
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Role" />
                </ListItem>
                <ListItem button component={Link} to="/user">
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="User" />
                </ListItem>
                <ListItem button component={Link} to="/api-resource">
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="ApiResource" />
                </ListItem>
                <ListItem button component={Link} to="/client">
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Client" />
                </ListItem>
            </List>
            <Divider />
        </Drawer>
    )
}
