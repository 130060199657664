import { Box, Button, Paper, TextField } from '@material-ui/core'
import Alert from 'components/Alert'
import { push } from 'connected-react-router'
import { UserCreate as UserCreateModel } from 'models/User'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import agent from '../../agent'
import { addUser } from './userSlice'

export default function UserCreate() {
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm<UserCreateModel>({
        mode: 'onChange',
    })
    const [formError, setFormError] = useState('')

    const onSubmit = (user: UserCreateModel) => {
        agent.User.add(user).then(res => {
            if (res.ok && res.data) {
                if (res.data.ok) {
                    dispatch(addUser(res.data.data))
                    dispatch(push('/user'))
                } else setFormError(res.data.error)
            } else {
            }
        })
    }

    return (
        <Box component={Paper} p={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField name="name" inputRef={register({ required: true })} error={!!errors.name} label="Name" />
                </div>

                <div>
                    <TextField
                        name="familyName"
                        inputRef={register({ required: true })}
                        error={!!errors.familyName}
                        label="Family name"
                    />
                </div>

                <div>
                    <TextField
                        name="username"
                        inputRef={register({ required: true })}
                        error={!!errors.username}
                        label="Username"
                    />
                </div>

                <div>
                    <TextField
                        name="email"
                        inputRef={register({ required: true })}
                        error={!!errors.email}
                        label="Email"
                    />
                </div>

                <div>
                    <TextField
                        type="password"
                        name="password"
                        inputRef={register({ required: true })}
                        error={!!errors.password}
                        label="Password"
                    />
                </div>

                {formError && (
                    <Alert elevation={6} variant="filled" severity="error">
                        {formError}
                    </Alert>
                )}

                <Box my={2}>
                    <Button type="submit" color="primary" variant="contained">
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
