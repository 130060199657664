import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import ClientCreate from './ClientCreate'
import ClientEdit from './ClientEdit'
import ClientInfo from './ClientInfo'
import ClientList from './ClientList'

export default function ClientRoot() {
    let { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ClientList />
            </Route>
            <Route path={`${path}/create`}>
                <ClientCreate />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <ClientEdit />
            </Route>
            <Route path={`${path}/:id`}>
                <ClientInfo />
            </Route>
            <Redirect to={path} />
        </Switch>
    )
}
