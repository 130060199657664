export default class GenKey {
    iterator: IterableIterator<number>

    constructor() {
        this.iterator = this.generator(0)
        this.iterator.next()
    }
    *generator(count: number): IterableIterator<number> {
        while (true) yield count++
    }

    next(): number {
        return this.iterator.next().value
    }
}
