import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'
import React from 'react'

import routes from '../routes/index'

interface AppProp {
    history: History
}

export default function App({ history }: AppProp) {
    return <ConnectedRouter history={history}>{routes}</ConnectedRouter>
}
