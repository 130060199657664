import { Box, Button, Paper, TextField } from '@material-ui/core'
import { RootState } from 'app/store'
import Alert from 'components/Alert'
import { push } from 'connected-react-router'
import { UserEdit as UserEditModel } from 'models/User'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import agent from '../../agent'
import { editUser } from './userSlice'

export default function UserEdit() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const user = useSelector((state: RootState) => state.user.users.find(u => u.id === id))

    const userEdit = {
        ...user,
        name: user?.claims?.find(c => c.claimType === 'name')?.claimValue,
        familyName: user?.claims?.find(c => c.claimType === 'family_name')?.claimValue,
    }

    const { register, handleSubmit, errors } = useForm<UserEditModel>({
        mode: 'onChange',
        defaultValues: userEdit || {},
    })
    const [formError, setFormError] = useState('')

    const onSubmit = (user: UserEditModel) => {
        agent.User.update(user).then(res => {
            if (res.ok && res.data) {
                if (res.data.ok) {
                    dispatch(editUser(res.data.data))
                    dispatch(push('/user'))
                } else setFormError(res.data.error)
            } else {
            }
        })
    }

    return (
        <Box component={Paper} p={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField name="name" inputRef={register({ required: true })} error={!!errors.name} label="Name" />
                </div>

                <div>
                    <TextField
                        name="familyName"
                        inputRef={register({ required: true })}
                        error={!!errors.familyName}
                        label="Family name"
                    />
                </div>

                <div>
                    <TextField
                        name="userName"
                        inputRef={register({ required: true })}
                        error={!!errors.userName}
                        label="Username"
                    />
                </div>

                <div>
                    <TextField
                        name="email"
                        inputRef={register({ required: true })}
                        error={!!errors.email}
                        label="Email"
                    />
                </div>

                {formError && (
                    <Alert elevation={6} variant="filled" severity="error">
                        {formError}
                    </Alert>
                )}

                <Box my={2}>
                    <Button type="submit" color="primary" variant="contained">
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
