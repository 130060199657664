import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { reducer as oidcReducer } from 'redux-oidc'
import ui from 'theme/uiSlice'

import apiResource from '../features/api-resource/apiResourceSlice'
import client from '../features/client/clientSlice'
import role from '../features/role/roleSlice'
import user from '../features/user/userSlice'

const rootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        oidc: oidcReducer,
        client,
        role,
        apiResource,
        user,
        ui,
    })

export default rootReducer
