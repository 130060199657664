import { makeStyles } from '@material-ui/core'
import userManager from 'app/userManager'
import React from 'react'

const useStyle = makeStyles({
    login: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&:before, &:after': { boxSizing: 'border-box' },
    },
    boxes: {
        '--size': '32px',
        '--duration': '800ms',
        height: 'calc(var(--size) * 2)',
        width: 'calc(var(--size) * 3)',
        position: 'relative',
        transformStyle: 'preserve-3d',
        transformOrigin: '50% 50%',
        marginTop: 'calc(var(--size) * 1.5 * -1)',
        transform: 'rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px)',

        '& > div:nth-child(1)': {
            transform: 'translate(100%, 0)',
            animation: '$box1 var(--duration) linear infinite',
        },
        '& > div:nth-child(2)': {
            transform: 'translate(0, 100%)',
            animation: '$box2 var(--duration) linear infinite',
        },
        '& > div:nth-child(3)': {
            transform: 'translate(100%, 100%)',
            animation: '$box3 var(--duration) linear infinite',
        },
        '& > div:nth-child(4)': {
            transform: 'translate(200%, 0)',
            animation: '$box4 var(--duration) linear infinite',
        },
    },
    box: {
        width: 'var(--size)',
        height: 'var(--size)',
        top: '0',
        left: '0',
        position: 'absolute',
        transformStyle: 'preserve-3d',

        '& div': {
            '--background': '#5C8DF6',
            '--top': 'auto',
            '--right': 'auto',
            '--bottom': 'auto',
            '--left': 'auto',
            '--translateZ': 'calc(var(--size) / 2)',
            '--rotateY': '0deg',
            '--rotateX': '0deg',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'var(--background)',
            top: 'var(--top)',
            right: 'var(--right)',
            bottom: 'var(--bottom)',
            left: 'var(--left)',
            transform: 'rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ))',
        },

        '& div:nth-child(1)': { '--top': '0', '--left': '0' },
        '& div:nth-child(2)': {
            '--background': '#135af2',
            '--right': 0,
            '--rotateY': '90deg',
        },
        '& div:nth-child(3)': {
            '--background': '#5c8df6',
            '--rotateX': '-90deg',
        },
        '& div:nth-child(4)': {
            '--background': '#DBE3F4',
            '--top': '0',
            '--left': '0',
            '--translateZ': 'calc(var(--size) * 3 * -1)',
        },
    },
    '@keyframes box1': {
        '0%, 50%': { transform: 'translate(100%, 0)' },
        '100%': { transform: 'translate(200%, 0)' },
    },
    '@keyframes box2': {
        '0%': { transform: 'translate(0, 100%)' },
        '50%': { transform: 'translate(0, 0)' },
        '100%': { transform: 'translate(100%, 0)' },
    },
    '@keyframes box3': {
        '0%, 50%': { transform: 'translate(100%, 100%)' },
        '100%': { transform: 'translate(0, 100%)' },
    },
    '@keyframes box4': {
        '0%': { transform: 'translate(200%, 0)' },
        '50%': { transform: 'translate(200%, 100%)' },
        '100%': { transform: 'translate(100%, 100%)' },
    },
})

export default function LoginPage() {
    const classes = useStyle()

    try {
        userManager.signinRedirect({ data: { path: window.location.pathname } })
    } catch (err) {
        console.error(err)
    }

    return (
        <div className={classes.login}>
            <div className={classes.boxes}>
                <div className={classes.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={classes.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={classes.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={classes.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}
