import { List, ListItem, ListItemText } from '@material-ui/core'
import { RootState } from 'app/store'
import InfoCard from 'components/InfoCard'
import { ApiResource } from 'models/ApiResource'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function ApiResourceInfo() {
    let { id } = useParams()
    const apiResource = useSelector((state: RootState) => {
        return state.apiResource.apiResources.find(a => a.id === Number(id))
    })

    if (apiResource)
        return (
            <InfoCard<ApiResource> title={'Api resource'} model={apiResource}>
                <List>
                    <ListItem>
                        <ListItemText primary={'Name'} secondary={apiResource.name} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Display Name'} secondary={apiResource.displayName} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Enabled'} secondary={apiResource.enabled ? 'true' : 'false'} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Description'} secondary={apiResource.description} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Scopes'} />
                    </ListItem>
                    <List component="div" disablePadding dense>
                        {apiResource.scopes?.map(scope => (
                            <ListItem key={scope.id}>
                                <ListItemText secondary={scope.name + ' - ' + scope.displayName} />
                            </ListItem>
                        ))}
                    </List>
                </List>
            </InfoCard>
        )
    else return <></>
}
