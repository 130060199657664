import { Box, Button, Paper, TextField } from '@material-ui/core'
import Alert from 'components/Alert'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { FormProp } from '../../models/FormProp'
import { Role } from '../../models/Role'

export default function RoleForm({ value, formRequest, afterSubmit }: FormProp<Role>) {
    const { handleSubmit, errors, register } = useForm<Role>({
        mode: 'onChange',
        defaultValues:
            value ||
            ({
                name: '',
            } as Role),
    })
    const [formError, setFormError] = useState('')

    const onSubmit = (role: Role) => {
        formRequest(role).then(res => {
            if (res.ok) {
                if (res.data.ok) afterSubmit(role)
                else setFormError(res.data.error)
            } else {
                setFormError(res.data)
            }
        })
    }

    return (
        <Box component={Paper} p={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField name="name" inputRef={register({ required: true })} error={!!errors.name} label="Name" />
                </div>

                {formError && (
                    <Alert elevation={6} variant="filled" severity="error">
                        {formError}
                    </Alert>
                )}

                <Box my={2}>
                    <Button type="submit" color="primary" variant="contained">
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
