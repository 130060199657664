import { RootState } from 'app/store'
import { push } from 'connected-react-router'
import { Client, ClientEdit as ClientEditModel } from 'models/Client'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import agent from '../../agent'
import ClientForm from './ClientForm'
import { editClient } from './clientSlice'

export default function ClientEdit() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const client = useSelector((state: RootState) => {
        return state.client.clients.find(c => c.id === Number(id))
    })

    const [clientEdit, setClientEdit] = useState<ClientEditModel>()

    useEffect(() => {
        if (client != null)
            setClientEdit({
                ...client,
                allowedScopes: client.allowedScopes?.map(a => a.scope) || [],
                allowedGrantTypes: client.allowedGrantTypes?.map(a => a.grantType),
                redirectUris: client.redirectUris?.map(u => u.redirectUri),
                postLogoutRedirectUris: client.postLogoutRedirectUris?.map(u => u.postLogoutRedirectUri),
                allowedCorsOrigins: client.allowedCorsOrigins?.map(o => o.origin),
                secret: client.clientSecrets?.find(x => x !== undefined)?.value,
            } as ClientEditModel)
    }, [setClientEdit, client])

    const afterSubmit = (client: Client) => {
        dispatch(editClient(client))
        dispatch(push('/client'))
    }

    return clientEdit ? (
        <ClientForm value={clientEdit} afterSubmit={afterSubmit} formRequest={agent.Client.update} />
    ) : (
        <p>No client</p>
    )
}
