import { push } from 'connected-react-router'
import { ApiResource } from 'models/ApiResource'
import React from 'react'
import { useDispatch } from 'react-redux'

import agent from '../../agent'
import ApiResourceForm from './ApiResourceForm'
import { addApiResouce } from './apiResourceSlice'

export default function ApiResourceCreate() {
    const dispatch = useDispatch()

    const afterSubmit = (apiResource: ApiResource) => {
        dispatch(addApiResouce(apiResource))
        dispatch(push('/api-resource'))
    }

    return <ApiResourceForm afterSubmit={afterSubmit} formRequest={agent.ApiResource.add} />
}
