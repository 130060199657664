import { Card, Fab, TableContainer, Toolbar, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

type ListCardProps = {
    title: string
    createUrl: string
}

const useStyle = makeStyles({
    title: {
        flexGrow: 1,
    },
})

export default function ListCard({ title, createUrl, children }: PropsWithChildren<ListCardProps>) {
    const classes = useStyle()

    return (
        <Card elevation={3}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>

                <Fab color="primary" size="small" aria-label="add" component={Link} to={createUrl}>
                    <Add />
                </Fab>
            </Toolbar>
            <TableContainer>{children}</TableContainer>
        </Card>
    )
}
