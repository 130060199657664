import { List, ListItem, ListItemText } from '@material-ui/core'
import { RootState } from 'app/store'
import InfoCard from 'components/InfoCard'
import { User } from 'models/User'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function UserInfo() {
    let { id } = useParams()

    const user = useSelector((state: RootState) => state.user.users.find(u => u.id === id))

    if (user)
        return (
            <InfoCard<User> title={'Korisnik: ' + user?.userName} model={user}>
                <List>
                    {user.claims?.map(claim => (
                        <ListItem key={claim.claimType}>
                            <ListItemText primary={claim.claimType} secondary={claim.claimValue} />
                        </ListItem>
                    ))}
                </List>
            </InfoCard>
        )
    else return <></>
}
