import { WebStorageStateStore } from 'oidc-client'
import { createUserManager } from 'redux-oidc'

const userManagerConfig = {
    client_id: 'spa',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
    }/callback`,
    response_type: 'code',
    scope: 'openid profile email IdentityServerApi',
    authority: process.env.REACT_APP_AUTH_URL,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
    }/silent-callback`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const userManager = createUserManager(userManagerConfig)

userManager.events.addUserSignedOut(async () => {
    await userManager.removeUser()
})

userManager.events.addSilentRenewError((error: Error) => {
    console.error(error)
})

export default userManager
