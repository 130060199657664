import { RootState } from 'app/store'
import { push } from 'connected-react-router'
import { Role } from 'models/Role'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import agent from '../../agent'
import RoleForm from './RoleForm'
import { editRole } from './roleSlice'

export default function RoleEdit() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const role = useSelector((state: RootState) => state.role.roles.find(r => r.id === id))

    const afterSubmit = (role: Role) => {
        dispatch(editRole(role))
        dispatch(push('/role'))
    }

    return role ? <RoleForm value={role} afterSubmit={afterSubmit} formRequest={agent.Role.update} /> : <p>No role</p>
}
