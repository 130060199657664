import SilentCallback from 'auth/SilentCallback'
import Home from 'layout/Home'
import React from 'react'
import { Route, Switch } from 'react-router'

import CallbackPage from '../auth/Callback'
import Main from '../auth/Main'
import ApiResourceRoot from '../features/api-resource/ApiResourceRoot'
import ClientRoot from '../features/client/ClientRoot'
import RoleRoot from '../features/role/RoleRoot'
import UserRoot from '../features/user/UserRoot'
import MainLayout from '../layout/MainLayout'
import NoMatch from '../layout/NoMatch'
import LayoutRoute from './LayoutRoute'

const routes = (
    <Switch>
        <Route exact path="/" component={Main} />

        <Route path="/callback" component={CallbackPage} />
        <Route exact path="/silent-callback" component={SilentCallback} />

        <LayoutRoute path="/home" layout={MainLayout} component={Home} />

        <LayoutRoute path="/user" layout={MainLayout} component={UserRoot} />

        <LayoutRoute path="/role" layout={MainLayout} component={RoleRoot} />

        <LayoutRoute path="/api-resource" layout={MainLayout} component={ApiResourceRoot} />

        <LayoutRoute path="/client" layout={MainLayout} component={ClientRoot} />

        <LayoutRoute path="*" component={NoMatch} layout={MainLayout} />
    </Switch>
)

export default routes
