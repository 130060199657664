import { push } from 'connected-react-router'
import { Role } from 'models/Role'
import React from 'react'
import { useDispatch } from 'react-redux'

import agent from '../../agent'
import RoleForm from './RoleForm'
import { addRole } from './roleSlice'

export default function RoleCreate() {
    const dispatch = useDispatch()

    const afterSubmit = (role: Role) => {
        dispatch(addRole(role))
        dispatch(push('/role'))
    }

    return <RoleForm afterSubmit={afterSubmit} formRequest={agent.Role.add} />
}
