import { Container, CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core'
import { RootState } from 'app/store'
import React from 'react'
import { useSelector } from 'react-redux'
import { dark, light } from 'theme/theme'

import Header from './Header'
import Sidebar from './Sidebar'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBarSpacer: theme.mixins.toolbar,
}))

export default function MainLayout(props: any) {
    const { isDarkMode } = useSelector((state: RootState) => state.ui)
    const classes = useStyles()

    return (
        <ThemeProvider theme={isDarkMode ? dark : light}>
            <div className={classes.root}>
                <CssBaseline />
                <Header />
                <Sidebar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        {props.children}
                    </Container>
                </main>
            </div>
        </ThemeProvider>
    )
}
