import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import RoleCreate from './RoleCreate'
import RoleEdit from './RoleEdit'
import RoleInfo from './RoleInfo'
import RoleList from './RoleList'

export default function RoleRoot() {
    let { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <RoleList />
            </Route>
            <Route path={`${path}/create`}>
                <RoleCreate />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <RoleEdit />
            </Route>
            <Route path={`${path}/:id`}>
                <RoleInfo />
            </Route>
            <Redirect to={path} />
        </Switch>
    )
}
