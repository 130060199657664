import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import UserCreate from './UserCreate'
import UserEdit from './UserEdit'
import UserInfo from './UserInfo'
import UserList from './UserList'

export default function UserRoot() {
    let { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <UserList />
            </Route>
            <Route path={`${path}/create`}>
                <UserCreate />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <UserEdit />
            </Route>
            <Route path={`${path}/:id`}>
                <UserInfo />
            </Route>
            <Redirect to={path} />
        </Switch>
    )
}
