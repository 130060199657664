import { List, ListItem, ListItemText } from '@material-ui/core'
import { RootState } from 'app/store'
import InfoCard from 'components/InfoCard'
import { Role } from 'models/Role'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function RoleInfo() {
    let { id } = useParams()

    const role = useSelector((state: RootState) => state.role.roles.find(r => r.id === id))

    if (role)
        return (
            <InfoCard<Role> title={'Role'} model={role}>
                <List>
                    <ListItem>
                        <ListItemText primary={'Name'} secondary={role.name} />
                    </ListItem>
                </List>
            </InfoCard>
        )
    else return <></>
}
