import { Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

type InfoCardProp<T> = {
    model: T
    title: string
}

const useStyles = makeStyles(() => ({
    card: {},
}))

export default function InfoCard<T>({ title, children }: PropsWithChildren<InfoCardProp<T>>) {
    const classes = useStyles()

    return (
        <Card elevation={3} className={classes.card}>
            <CardHeader title={title} />
            <Divider light variant="middle" />
            <CardContent>{children}</CardContent>
        </Card>
    )
}
