import { Box, Button, Paper, Switch, TextField } from '@material-ui/core'
import Alert from 'components/Alert'
import ListInput from 'components/ListInput'
import { ApiResource, ApiResourceModel } from 'models/ApiResource'
import { FormProp } from 'models/FormProp'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export default function ApiResourceForm({ value, formRequest, afterSubmit }: FormProp<ApiResourceModel, ApiResource>) {
    const { handleSubmit, errors, control, register } = useForm<ApiResourceModel>({
        mode: 'onChange',
        defaultValues:
            value ||
            ({
                name: '',
                displayName: '',
                enabled: false,
                nonEditable: false,
                apiSecrets: [],
            } as ApiResourceModel),
    })
    const [formError, setFormError] = useState('')

    const onSubmit = (apiResource: ApiResourceModel) => {
        formRequest(apiResource).then(res => {
            if (res.ok && res.data) {
                if (res.data.ok) afterSubmit(res.data.data)
                else setFormError(res.data.error)
            } else {
            }
        })
    }

    return (
        <Box component={Paper} p={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField name="name" inputRef={register({ required: true })} error={!!errors.name} label="Name" />
                </div>

                <div>
                    <TextField
                        name="displayName"
                        inputRef={register({ required: true })}
                        error={!!errors.displayName}
                        label="Dispaly name"
                    />
                </div>

                <div>
                    <label>Enabled</label>
                    <Controller as={Switch} type="checkbox" name="enabled" control={control} />
                </div>

                <div>
                    <TextField
                        name="description"
                        inputRef={register({ required: true })}
                        error={!!errors.description}
                        label="Description"
                    />
                </div>

                <ListInput name="scopes" label="Scopes" singleLabel="Scope" error={!!errors.scopes} control={control} />

                <ListInput
                    name="apiSecrets"
                    label="Secrets"
                    singleLabel="Secret"
                    error={!!errors.apiSecrets}
                    control={control}
                />

                {formError && (
                    <Alert elevation={6} variant="filled" severity="error">
                        {formError}
                    </Alert>
                )}

                <Box my={2}>
                    <Button type="submit" color="primary" variant="contained">
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
