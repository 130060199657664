import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import agent from 'agent'
import { AppThunk } from 'app/store'
import { User } from 'models/User'

interface UserState {
    users: User[]
}

const userInitialState: UserState = {
    users: [],
}

var userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        setUsers: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload
        },
        addUser: (state, action: PayloadAction<User>) => {
            state.users.push(action.payload)
        },
        editUser: (state, action: PayloadAction<User>) => {
            state.users.map(u => (u.id === action.payload.id ? action.payload : u))
        },
        deleteUser: (state, action: PayloadAction<string>) => {
            state.users = state.users.filter(u => u.id !== action.payload)
        },
    },
})

export const { setUsers, addUser, editUser, deleteUser } = userSlice.actions

export default userSlice.reducer

export const fetchUsers = (): AppThunk => async dispatch => {
    try {
        const users = await agent.User.getAll()
        if (users.ok && users.data) dispatch(setUsers(users.data))
    } catch (err) {
        console.error(err)
    }
}
