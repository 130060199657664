import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Role } from 'models/Role'

interface RoleState {
    roles: Role[]
}

const roleInitialState: RoleState = {
    roles: [],
}

const roleSlice = createSlice({
    name: 'role',
    initialState: roleInitialState,
    reducers: {
        setRoles: (state, action: PayloadAction<Role[]>) => {
            state.roles = action.payload
        },
        addRole: (state, action: PayloadAction<Role>) => {
            state.roles.push(action.payload)
        },
        editRole: (state, action: PayloadAction<Role>) => {
            state.roles.map(r => (r.id === action.payload.id ? action.payload : r))
        },
        deleteRole: (state, action: PayloadAction<string>) => {
            state.roles = state.roles.filter(r => r.id !== action.payload)
        },
    },
})

export const { setRoles, addRole, editRole, deleteRole } = roleSlice.actions

export default roleSlice.reducer
