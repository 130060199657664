import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from 'models/Client'

interface ClientState {
    clients: Client[]
}

const clientInitialState: ClientState = {
    clients: [],
}

var clientSlice = createSlice({
    name: 'client',
    initialState: clientInitialState,
    reducers: {
        setClients: (state, action: PayloadAction<Client[]>) => {
            state.clients = action.payload
        },
        addClient: (state, action: PayloadAction<Client>) => {
            state.clients.push(action.payload)
        },
        editClient: (state, action: PayloadAction<Client>) => {
            state.clients.map(c => (c.id === action.payload.id ? action.payload : c))
        },
        deleteClient: (state, action: PayloadAction<number>) => {
            state.clients = state.clients.filter(c => c.id !== action.payload)
        },
    },
})

export const { setClients, addClient, editClient, deleteClient } = clientSlice.actions

export default clientSlice.reducer
