import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiResource } from 'models/ApiResource'

interface ApiResourceState {
    apiResources: ApiResource[]
}

const apiResourceInitialState: ApiResourceState = {
    apiResources: [],
}

var apiResourceSlice = createSlice({
    name: 'apiResource',
    initialState: apiResourceInitialState,
    reducers: {
        setApiResources: (state, action: PayloadAction<ApiResource[]>) => {
            state.apiResources = action.payload
        },
        addApiResouce: (state, action: PayloadAction<ApiResource>) => {
            state.apiResources.push(action.payload)
        },
        editApiResource: (state, action: PayloadAction<ApiResource>) => {
            state.apiResources.map(ar => (ar.id === action.payload.id ? action.payload : ar))
        },
        deleteApiResource: (state, action: PayloadAction<number>) => {
            state.apiResources = state.apiResources.filter(ar => ar.id !== action.payload)
        },
    },
})

export const { setApiResources, addApiResouce, editApiResource, deleteApiResource } = apiResourceSlice.actions

export default apiResourceSlice.reducer
