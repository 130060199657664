import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import { RootState } from 'app/store'
import ListCard from 'components/ListCard'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import agent from '../../agent'
import { deleteRole, setRoles } from './roleSlice'

export default function RoleList() {
    const dispatch = useDispatch()

    const { roles } = useSelector((state: RootState) => state.role)

    useEffect(() => {
        agent.Role.getAll().then(res => {
            if (res.ok && res.data) dispatch(setRoles(res.data))
            else;
        })
    }, [dispatch])

    const onInfoRole = (id: string) => {
        dispatch(push(`/role/${id}`))
    }

    const onEditRole = (id: string) => {
        dispatch(push(`/role/edit/${id}`))
    }

    const onDeleteRole = (id: string) => {
        agent.Role.delete(id).then(res => {
            if (res.ok && res.data) {
                if (res.data.ok) {
                    dispatch(deleteRole(id))
                } else {
                }
            }
        })
    }

    return (
        <ListCard title="Roles" createUrl="/role/create">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {roles.map(role => (
                        <TableRow key={role.id}>
                            <TableCell>
                                <Avatar src="/logo512.png" />
                            </TableCell>
                            <TableCell>{role.name}</TableCell>
                            <TableCell>
                                <ButtonGroup variant="contained" color="primary">
                                    <Button onClick={() => onInfoRole(role.id)}>
                                        <InfoIcon />
                                    </Button>
                                    <Button onClick={() => onEditRole(role.id)}>
                                        <EditIcon />
                                    </Button>
                                    <Button onClick={() => onDeleteRole(role.id)}>
                                        <DeleteIcon />
                                    </Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ListCard>
    )
}
