import { AppBar, IconButton, makeStyles, Toolbar } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {
    AccountCircleOutlined as AccountsIcon,
    Brightness4Outlined as ToggleDarkModeIcon,
    Brightness5Outlined as ToggleLightModeIcon,
    Menu as MenuIcon,
} from '@material-ui/icons'
import { RootState } from 'app/store'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDark, toggleNavbar } from 'theme/uiSlice'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
}))

export default function Header() {
    const dispatch = useDispatch()

    const classes = useStyles()
    const { isDarkMode, navbarOpen } = useSelector((state: RootState) => state.ui)

    const menuId = 'primary-search-account-menu'

    const onDarkModeToggle = () => {
        dispatch(setDark(!isDarkMode))
    }

    const onToggleNavBar = () => {
        dispatch(toggleNavbar())
    }

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, navbarOpen && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    edge="start"
                    className={classes.menuButton}
                    aria-label="open drawer"
                    onClick={onToggleNavBar}>
                    <MenuIcon />
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    Dashboard
                </Typography>
                <div>
                    <IconButton
                        color="inherit"
                        aria-label="toggle dark theme"
                        aria-controls={menuId}
                        onClick={onDarkModeToggle}>
                        {isDarkMode ? <ToggleLightModeIcon /> : <ToggleDarkModeIcon />}
                    </IconButton>
                </div>
                <div>
                    <IconButton color="inherit" edge="end">
                        <AccountsIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    )
}
