import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const lightMuiTheme = createMuiTheme({
    palette: {
        type: 'light',
    },
    shape: {
        borderRadius: 0.5,
    },
})

const darkMuiTheme = createMuiTheme({
    palette: {
        type: 'dark',
    },
    shape: {
        borderRadius: 0.5,
    },
})

export const light = responsiveFontSizes(lightMuiTheme)
export const dark = responsiveFontSizes(darkMuiTheme)
