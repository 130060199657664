import { Box, FormControl, IconButton, Input, InputLabel } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useEffect, useState } from 'react'

import GenKey from '../helpers/genKey'

interface ListInputProp {
    label: string
    singleLabel: string
    name: string
    defaultValue?: any
    control: any
    error: boolean
}

interface Item {
    key: number
    value: string
}

let genKey = new GenKey()

const genItem = (value: string): Item => ({
    key: genKey.next(),
    value: value,
})

const isUndefined = (val: any) => val === undefined
const isNullOrUndefined = (val: any) => val == null
const unique = (val: any) => val.filter(Boolean)

const get = (obj: any, path: any, defaultValue: any) => {
    const result = unique(path.split(/[,[\].]+?/)).reduce(
        (result: any, key: any) => (isNullOrUndefined(result) ? result : result[key]),
        obj
    )

    return isUndefined(result) || result === obj ? (isUndefined(obj[path]) ? defaultValue : obj[path]) : result
}

export default function ListInput({ label, singleLabel, name, control, error }: ListInputProp) {
    const { defaultValuesRef, register } = control
    const [input, setInput] = useState('')
    const [items, setItems] = useState<Item[]>([])

    useEffect(() => {
        let values = get(defaultValuesRef.current, name, [])
        if (values) setItems(values.map((i: string) => genItem(i)))
    }, [defaultValuesRef, name])

    const removeItem = (key: number) => {
        setItems(items.filter(i => i.key !== key))
    }

    const addItem = (item: Item) => {
        setItems(items => [...items, item])
    }

    const replaceItem = (item: Item) => {
        setItems(items.map(i => (i.key === item.key ? item : i)))
    }

    const add = () => {
        if (input) {
            addItem(genItem(input))
            setInput('')
        } else {
        }
    }

    const inputChange = (event: any) => {
        setInput(event.target.value)
    }

    const itemChange = (e: any, key: number) => {
        let item = items.find(i => i.key === key)
        if (item) {
            item.value = e.target.value
            replaceItem(item)
        }
    }

    return (
        <Box border={1} p={1} my={2} borderColor="grey.500" borderRadius={9} textAlign="center">
            <label>{label}</label>
            <div>
                {items.map((item, i) => (
                    <FormControl key={item.key}>
                        <InputLabel>{singleLabel}</InputLabel>
                        <Input
                            type="text"
                            name={`${name}[${i}]`}
                            value={item.value}
                            inputRef={register({ required: true })}
                            error={error}
                            onChange={e => itemChange(e, item.key)}
                            endAdornment={
                                <IconButton color="secondary" onClick={() => removeItem(item.key)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        />
                    </FormControl>
                ))}
            </div>

            <FormControl>
                <InputLabel>{singleLabel}</InputLabel>
                <Input
                    id={'list-input-' + name}
                    value={input}
                    onChange={inputChange}
                    endAdornment={
                        <IconButton color="primary" onClick={() => add()}>
                            <AddIcon />
                        </IconButton>
                    }
                />
            </FormControl>
        </Box>
    )
}
