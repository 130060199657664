import { push } from 'connected-react-router'
import React from 'react'
import { useDispatch } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'

import userManager from '../app/userManager'

export default function CallbackPage() {
    const dispatch = useDispatch()

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={user => {
                dispatch(push(user.state.path))
            }}
            errorCallback={error => {
                dispatch(push('/'))
                console.error('callback', error)
            }}>
            <div>Redirecting...</div>
        </CallbackComponent>
    )
}
