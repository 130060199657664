import { List, ListItem, ListItemText } from '@material-ui/core'
import { RootState } from 'app/store'
import InfoCard from 'components/InfoCard'
import { Client } from 'models/Client'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function ClientInfo() {
    let { id } = useParams()
    const client = useSelector((state: RootState) => {
        return state.client.clients.find(c => c.id === Number(id))
    })

    return client ? (
        <InfoCard<Client> title={'Client: ' + client?.clientName} model={client}>
            <List>
                <ListItem>
                    <ListItemText primary="Client id" secondary={client.clientId} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Client name" secondary={client.clientName} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="protocolType" secondary={client.protocolType} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="redirectUris" />
                </ListItem>
                <List component="div" disablePadding dense>
                    {client.redirectUris?.map(uri => (
                        <ListItem key={uri.id}>
                            <ListItemText secondary={uri.redirectUri} />
                        </ListItem>
                    ))}
                </List>
                <ListItem>
                    <ListItemText primary="postLogoutRedirectUris" />
                </ListItem>
                <List component="div" disablePadding dense>
                    {client.postLogoutRedirectUris?.map(uri => (
                        <ListItem key={uri.id}>
                            <ListItemText secondary={uri.postLogoutRedirectUri} />
                        </ListItem>
                    ))}
                </List>
                <ListItem>
                    <ListItemText primary="allowedCorsOrigins" />
                </ListItem>
                <List component="div" disablePadding dense>
                    {client.allowedCorsOrigins?.map(origin => (
                        <ListItem key={origin.id}>
                            <ListItemText secondary={origin.origin} />
                        </ListItem>
                    ))}
                </List>
                <ListItem>
                    <ListItemText primary="allowedGrantTypes" />
                </ListItem>
                <List component="div" disablePadding dense>
                    {client.allowedGrantTypes?.map(grant => (
                        <ListItem key={grant.id}>
                            <ListItemText secondary={grant.grantType} />
                        </ListItem>
                    ))}
                </List>
                <ListItem>
                    <ListItemText primary="allowedScopes" />
                </ListItem>
                <List component="div" disablePadding dense>
                    {client.allowedScopes?.map(scope => (
                        <ListItem key={scope.id}>
                            <ListItemText secondary={scope.scope} />
                        </ListItem>
                    ))}
                </List>
            </List>
        </InfoCard>
    ) : (
        <></>
    )
}
