import { push } from 'connected-react-router'
import { Client } from 'models/Client'
import React from 'react'
import { useDispatch } from 'react-redux'

import agent from '../../agent'
import ClientForm from './ClientForm'
import { addClient } from './clientSlice'

export default function ClientCreate() {
    const dispatch = useDispatch()

    const afterSubmit = (client: Client) => {
        dispatch(addClient(client))
        dispatch(push('/client'))
    }

    return <ClientForm afterSubmit={afterSubmit} formRequest={agent.Client.add} />
}
