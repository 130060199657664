import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import { RootState } from 'app/store'
import ListCard from 'components/ListCard'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import agent from '../../agent'
import { deleteApiResource, setApiResources } from './apiResourceSlice'

export default function ApiResourceList() {
    const dispatch = useDispatch()

    const apiResources = useSelector((state: RootState) => state.apiResource.apiResources)

    useEffect(() => {
        agent.ApiResource.getAll().then(res => {
            if (res.ok && res.data) dispatch(setApiResources(res.data))
        })
    }, [dispatch])

    const onInfoApiResource = (id: number) => {
        dispatch(push(`/api-resource/${id}`))
    }

    const onEditApiResource = (id: number) => {
        dispatch(push(`/api-resource/edit/${id}`))
    }

    const onDeleteApiResource = (id: number) => {
        agent.ApiResource.delete(id).then(res => {
            if (res.ok && res.data) {
                if (res.data.ok) {
                    dispatch(deleteApiResource(id))
                } else {
                }
            }
        })
    }

    return (
        <ListCard title="Api Resources" createUrl="/api-resource/create">
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Display name</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {apiResources.map(apiResource => (
                        <TableRow key={apiResource.id}>
                            <TableCell>{apiResource.id}</TableCell>
                            <TableCell>{apiResource.name}</TableCell>
                            <TableCell>{apiResource.displayName}</TableCell>
                            <TableCell>
                                <ButtonGroup variant="contained" color="primary">
                                    <Button onClick={() => onInfoApiResource(apiResource.id)}>
                                        <InfoIcon />
                                    </Button>
                                    <Button onClick={() => onEditApiResource(apiResource.id)}>
                                        <EditIcon />
                                    </Button>
                                    <Button onClick={() => onDeleteApiResource(apiResource.id)}>
                                        <DeleteIcon />
                                    </Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ListCard>
    )
}
