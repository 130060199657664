import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UiState {
    isDarkMode: boolean
    navbarOpen: boolean
}

const uiInitialState: UiState = {
    isDarkMode: false,
    navbarOpen: true,
}

const uiSlice = createSlice({
    name: 'ui',
    initialState: uiInitialState,
    reducers: {
        setDark: (state, action: PayloadAction<boolean>) => {
            state.isDarkMode = action.payload
        },
        toggleNavbar: (state, action: PayloadAction) => {
            state.navbarOpen = !state.navbarOpen
        },
    },
})

export const { setDark, toggleNavbar } = uiSlice.actions

export default uiSlice.reducer
