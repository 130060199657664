import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { PersistGate } from 'redux-persist/integration/react'

import App from './app/App'
import store, { history, persistor } from './app/store'
import userManager from './app/userManager'
import * as serviceWorker from './serviceWorker'

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <OidcProvider store={store} userManager={userManager}>
                    <App history={history} />
                </OidcProvider>
            </PersistGate>
        </Provider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV !== 'development') serviceWorker.register()
else serviceWorker.unregister()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./app/App', render)
}
