import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import ApiResourceCreate from './ApiResourceCreate'
import ApiResourceEdit from './ApiResourceEdit'
import ApiResourceInfo from './ApiResourceInfo'
import ApiResourceList from './ApiResourceList'

export default function ApiResourceRoot() {
    let { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ApiResourceList />
            </Route>
            <Route path={`${path}/create`}>
                <ApiResourceCreate />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <ApiResourceEdit />
            </Route>
            <Route path={`${path}/:id`}>
                <ApiResourceInfo />
            </Route>
            <Redirect to={path} />
        </Switch>
    )
}
