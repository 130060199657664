import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import ContactsIcon from '@material-ui/icons/Contacts'
import PersonIcon from '@material-ui/icons/Person'
import agent from 'agent'
import { RootState } from 'app/store'
import CounterCard from 'components/CounterCard'
import { setUsers } from 'features/user/userSlice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    })
)

export default function Home() {
    const dispatch = useDispatch()
    const classes = useStyles()
    /*
    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])*/

    useEffect(() => {
        agent.User.getAll().then(res => {
            if (res.ok && res.data) dispatch(setUsers(res.data))
        })
    }, [dispatch])

    const userCount = useSelector((state: RootState) => state.user.users.length)

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <CounterCard link="/user" title="User" icon={<PersonIcon />} count={userCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <CounterCard link="/api-resource" title="Api Resource" icon={<CallSplitIcon />} count={userCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <CounterCard link="/client" title="Client" icon={<ContactsIcon />} count={userCount} />
            </Grid>
        </Grid>
    )
}
