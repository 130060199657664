import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Link } from 'react-router-dom'

export interface CounterCardProps {
    link: string
    title: string
    icon: any
    count: number
}

const useStyles = makeStyles(() => ({
    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.063)',
        },
    },
    card: {
        borderRadius: 16,
    },
    content: {
        padding: '1rem 1.5rem 1.5rem',
    },
    title: {
        fontSize: '2rem',
    },
}))

export default function CounterCard({ link, title, icon, count }: CounterCardProps) {
    var classes = useStyles()

    return (
        <Link to={link}>
            <CardActionArea className={classes.actionArea}>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item>{icon}</Grid>
                            <Grid item>
                                <Typography>{count}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </CardActionArea>
        </Link>
    )
}
