import { ApiResource } from './ApiResource'

export class SelectItem {
    public value: string = ''
    public text: string = ''

    constructor(value: string, text: string) {
        this.value = value
        this.text = text
    }

    static toGrantTypes(values: string[]): SelectItem[] {
        var selectItems: SelectItem[] = []

        values.forEach(v => {
            var selectItem = grantTypes.find(gt => gt.value === v)
            if (selectItem) selectItems.push(selectItem)
        })

        return selectItems
    }

    static toScopes(scopes: SelectItem[], values: string[]): SelectItem[] {
        var selectItems: SelectItem[] = []

        values.forEach(v => {
            var selectItem = scopes.find(s => s.value === v)
            if (selectItem) selectItems.push(selectItem)
        })

        return selectItems
    }
}

export const grantTypes: SelectItem[] = [
    { value: 'implicit', text: 'Implicit' },
    { value: 'hybrid', text: 'Hybrid' },
    { value: 'authorization_code', text: 'AuthorizationCode' },
    { value: 'client_credentials', text: 'ClientCredentials' },
    { value: 'password', text: 'ResourceOwnerPassword' },
    {
        value: 'urn:ietf:params:oauth:grant-type:device_code',
        text: 'DeviceFlow',
    },
]

export const scopes = (apiResources?: ApiResource[]): SelectItem[] => {
    var result = [
        { value: 'openid', text: 'OpenId' },
        { value: 'profile', text: 'Profile' },
        { value: 'email', text: 'Email' },
        { value: 'address', text: 'Address' },
        { value: 'phone', text: 'Phone' },
        { value: 'offline_access', text: 'OfflineAccess' },
    ]

    if (apiResources)
        for (var apiResource of apiResources)
            result.push({
                value: apiResource.name,
                text: apiResource.displayName,
            })

    return result
}
