import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import { RootState } from 'app/store'
import ListCard from 'components/ListCard'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import agent from '../../agent'
import { deleteClient, setClients } from './clientSlice'

export default function ClientList() {
    const dispatch = useDispatch()

    const { clients } = useSelector((state: RootState) => state.client)

    useEffect(() => {
        agent.Client.getAll().then(res => {
            if (res.ok && res.data) dispatch(setClients(res.data))
        })
    }, [dispatch])

    const onInfoClient = (id: number) => {
        dispatch(push(`/client/${id}`))
    }

    const onEditClient = (id: number) => {
        dispatch(push(`/client/edit/${id}`))
    }

    const onDeleteClient = (id: number) => {
        agent.Client.delete(id).then(res => {
            if (res.ok) {
                if (res.data?.ok) {
                    dispatch(deleteClient(id))
                } else {
                }
            }
        })
    }

    return (
        <ListCard title="Clients" createUrl="/client/create">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Client id</TableCell>
                        <TableCell>Client name</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map(client => (
                        <TableRow key={client.id}>
                            <TableCell>{client.id}</TableCell>
                            <TableCell>{client.clientId}</TableCell>
                            <TableCell>{client.clientName}</TableCell>
                            <TableCell>
                                <ButtonGroup variant="contained" color="primary">
                                    <Button onClick={() => onInfoClient(client.id)}>
                                        <InfoIcon />
                                    </Button>
                                    <Button onClick={() => onEditClient(client.id)}>
                                        <EditIcon />
                                    </Button>
                                    <Button onClick={() => onDeleteClient(client.id)}>
                                        <DeleteIcon />
                                    </Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ListCard>
    )
}
