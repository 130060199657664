import { SelectItem } from 'models/FormModels'

export interface ClientRedirectUri {
    id: number
    redirectUri: string
    clientId: number
    client?: Client
}

export interface ClientScope {
    id: number
    scope: string
    clientId: number
    client?: Client
}

export interface ClientSecret extends Secret {
    clientId: number
    client?: Client
}

export interface Property {
    id: number
    key?: string
    value?: string
}

export interface Secret {
    id: number
    description?: string
    value?: string
    expiration?: Date
    type?: string
    created: Date
}

export interface ClientGrantType {
    id: number
    grantType: string
    clientId: number
    client?: Client
}

export interface ClientCorsOrigin {
    id: number
    origin: string
    clientId: number
    client?: Client
}

export interface ClientClaim {
    id: number
    type: string
    value: string
    clientId: number
    client?: Client
}

export interface ClientPostLogoutRedirectUri {
    id: number
    postLogoutRedirectUri: string
    clientId: number
    client?: Client
}

export interface ClientProperty extends Property {
    clientId: number
    client?: Client
}

export interface Client {
    id: number
    enabled: boolean
    clientId?: string
    protocolType?: string
    clientSecrets?: Array<ClientSecret>
    requireClientSecret: boolean
    clientName?: string
    description?: string
    clientUri?: string
    logoUri?: string
    requireConsent: boolean
    allowRememberConsent: boolean
    alwaysIncludeUserClaimsInIdToken: boolean
    allowedGrantTypes?: Array<ClientGrantType>
    requirePkce: boolean
    allowPlainTextPkce: boolean
    allowAccessTokensViaBrowser: boolean
    redirectUris?: Array<ClientRedirectUri>
    postLogoutRedirectUris?: Array<ClientPostLogoutRedirectUri>
    frontChannelLogoutUri?: string
    frontChannelLogoutSessionRequired: boolean
    backChannelLogoutUri?: string
    backChannelLogoutSessionRequired: boolean
    allowOfflineAccess: boolean
    allowedScopes?: Array<ClientScope>
    identityTokenLifetime: number
    accessTokenLifetime: number
    authorizationCodeLifetime: number
    consentLifetime?: number
    absoluteRefreshTokenLifetime: number
    slidingRefreshTokenLifetime: number
    refreshTokenUsage: number
    updateAccessTokenClaimsOnRefresh: boolean
    refreshTokenExpiration: number
    accessTokenType: number
    enableLocalLogin: boolean
    includeJwtId: boolean
    claims?: Array<ClientClaim>
    alwaysSendClientClaims: boolean
    clientClaimsPrefix?: string
    pairWiseSubjectSalt?: string
    allowedCorsOrigins?: Array<ClientCorsOrigin>
    properties?: Array<ClientProperty>
    created: Date
    updated?: Date
    lastAccessed?: Date
    userSsoLifetime?: number
    userCodeType?: string
    deviceCodeLifetime: number
    nonEditable: boolean
}

export enum TokenUsage {
    ReUse = 0,
    OneTimeOnly = 1,
}

export enum TokenExpiration {
    Sliding = 0,
    Absolute = 1,
}

export enum AccessTokenType {
    Jwt = 0,
    Reference = 1,
}

export class ClientForm {
    public clientId: string = ''
    public clientName: string = ''
    public description: string = ''

    public enabled: boolean = false

    public redirectUris: string[] = []
    public postLogoutRedirectUris: string[] = []
    public allowedCorsOrigins: string[] = []

    public allowedGrantTypes: SelectItem[] = []
    public allowedScopes: SelectItem[] = []

    public accessTokenLifetime: number = 0

    public secret: string = ''

    constructor(model?: ClientEdit, scopes?: SelectItem[]) {
        if (model && scopes) {
            this.clientId = model.clientId
            this.clientName = model.clientName
            this.description = model.description

            this.enabled = model.enabled

            this.redirectUris = model.redirectUris
            this.postLogoutRedirectUris = model.postLogoutRedirectUris
            this.allowedCorsOrigins = model.allowedCorsOrigins

            this.allowedGrantTypes = SelectItem.toGrantTypes(model.allowedGrantTypes)
            this.allowedScopes = SelectItem.toScopes(scopes, model.allowedScopes)

            this.accessTokenLifetime = model.accessTokenLifetime

            this.secret = model.secret
        }
    }

    static toModel(client: ClientForm): ClientEdit {
        return {
            clientId: client.clientId,
            clientName: client.clientName,
            redirectUris: client.redirectUris,
            enabled: client.enabled,
            postLogoutRedirectUris: client.postLogoutRedirectUris,
            allowedCorsOrigins: client.allowedCorsOrigins,
            allowedGrantTypes: client.allowedGrantTypes?.map(gt => gt.value),
            allowedScopes: client.allowedScopes?.map(s => s.value),
            accessTokenLifetime: client.accessTokenLifetime,
            secret: client.secret,
        } as ClientEdit
    }
}

export interface ClientEdit {
    clientId: string
    clientName: string
    description: string
    enabled: boolean
    allowOfflineAccess: boolean
    requireClientSecret: boolean
    requireConsent: boolean
    allowedGrantTypes: string[]
    redirectUris: string[]
    postLogoutRedirectUris: string[]
    allowedCorsOrigins: string[]
    allowedScopes: string[]

    accessTokenLifetime: number

    secret: string
}
