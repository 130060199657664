import React from 'react'
import { Route } from 'react-router-dom'

interface LayoutRouteProp {
    component: any
    layout: any
    path: any
}

export default function LayoutRoute({ component: Component, layout: Layout, ...rest }: LayoutRouteProp) {
    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    )
}
