import { RootState } from 'app/store'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import LoginPage from './LoginPage'

export default function Main() {
    const { user } = useSelector((state: RootState) => state.oidc)
    return !user || user.expired ? <LoginPage /> : <Redirect to="/home" />
}
