import { Box, Button, Grid, Paper, Switch, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { RootState } from 'app/store'
import Alert from 'components/Alert'
import { Client, ClientEdit, ClientForm as ClientFormModel } from 'models/Client'
import { grantTypes, scopes, SelectItem } from 'models/FormModels'
import { FormProp } from 'models/FormProp'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import ListInput from '../../components/ListInput'

export default function ClientForm({ value, formRequest, afterSubmit }: FormProp<ClientEdit, Client>) {
    const apiResources = useSelector((state: RootState) => state.apiResource.apiResources)
    const clientScopes = scopes(apiResources)
    const { handleSubmit, errors, control, register } = useForm<ClientFormModel>({
        mode: 'onChange',
        defaultValues: new ClientFormModel(value ?? undefined, clientScopes),
    })
    const [formError, setFormError] = useState('')

    const onSubmit = (client: ClientFormModel) => {
        var model = ClientFormModel.toModel(client)
        formRequest(model).then(res => {
            if (res.ok && res.data) {
                if (res.data.ok) afterSubmit(res.data.data)
                else setFormError(res.data.error)
            } else {
                if (!res.ok)
                    setFormError(res.data)
            }
        })
    }

    return (
        <Box component={Paper} p={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <TextField
                        name="clientId"
                        inputRef={register({ required: true })}
                        error={!!errors.clientId}
                        label="Client id"
                    />
                </div>

                <div>
                    <TextField
                        name="clientName"
                        inputRef={register({ required: true })}
                        error={!!errors.clientName}
                        label="Client name"
                    />
                </div>

                <div>
                    <label>Enabled</label>
                    <Controller as={Switch} type="checkbox" name="enabled" control={control} />
                </div>

                <div>
                    <Controller
                        as={
                            <Autocomplete
                                options={grantTypes}
                                getOptionLabel={(option: SelectItem) => option.text}
                                renderInput={params => <TextField {...params} label="Allowed grant types" />}
                            />
                        }
                        multiple
                        onChange={([, data]) => {
                            return data
                        }}
                        name="allowedGrantTypes"
                        control={control}
                    />
                </div>

                <Grid container spacing={3}>
                    <Grid item sm={12} md>
                        <ListInput
                            name="redirectUris"
                            label="Redirect Uris"
                            singleLabel="Uri"
                            error={!!errors.redirectUris}
                            control={control}
                        />
                    </Grid>
                    <Grid item sm={12} md>
                        <ListInput
                            name="postLogoutRedirectUris"
                            label="Post Logout Redirect Uris"
                            singleLabel="Uri"
                            error={!!errors.postLogoutRedirectUris}
                            control={control}
                        />
                    </Grid>

                    <Grid item sm={12} md>
                        <ListInput
                            name="allowedCorsOrigins"
                            label="Allowed Cors Origins"
                            singleLabel="Cors Origin"
                            error={!!errors.allowedCorsOrigins}
                            control={control}
                        />
                    </Grid>
                </Grid>

                <div>
                    <Controller
                        as={
                            <Autocomplete
                                options={clientScopes}
                                getOptionLabel={(option: SelectItem) => option.text}
                                getOptionSelected={(option: SelectItem, value: SelectItem) =>
                                    option.value === value.value
                                }
                                renderInput={params => <TextField {...params} label="Allowed scopes" />}
                            />
                        }
                        multiple
                        onChange={([, data]) => data}
                        name="allowedScopes"
                        control={control}
                    />
                </div>

                <div>
                    <TextField
                        name="accessTokenLifetime"
                        type="number"
                        inputRef={register({ required: true })}
                        error={!!errors.accessTokenLifetime}
                        label="accessTokenLifetime"
                    />
                </div>

                <div>
                    <TextField
                        name="secret"
                        inputRef={register({ required: true })}
                        error={!!errors.secret}
                        label="Client Secret"
                    />
                </div>

                {formError && (
                    <Alert elevation={6} variant="filled" severity="error">
                        {formError}
                    </Alert>
                )}

                <Box my={2}>
                    <Button type="submit" color="primary" variant="contained">
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
