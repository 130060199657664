import store from './app/store'
import userManager from './app/userManager'
import { ApiResource, ApiResourceModel } from './models/ApiResource'
import { Client, ClientEdit } from './models/Client'
import { Res } from './models/ResponseHelper'
import { Role } from './models/Role'
import { User, UserCreate, UserEdit } from './models/User'

const API_ROOT = process.env.REACT_APP_API_URL

const getToken = () => {
    const token = store.getState().oidc.user?.access_token
    if (token == null)
        userManager.signinRedirect({
            data: { path: window.location.pathname },
        })

    return token
}

export interface SuccessfulResponse<T> {
    ok: true;
    data: T;
}

export interface ErrorResponse {
    ok: false;
    data: string;
}

export async function http<T>(path: string, args: RequestInit): Promise<SuccessfulResponse<T> | ErrorResponse> {
    if (path.startsWith('/')) path = API_ROOT + path

    args.headers = new Headers(args.headers)
    args.headers.append('Authorization', `Bearer ${getToken()}`)
    args.headers.append('Content-Type', 'application/json')

    var request = new Request(path, args)

    try {
        const response = await fetch(request)

        if (response.status === 401)
            userManager.signinRedirect({
                data: { path: window.location.pathname },
            })

        return {
            ok: true,
            data: await response.json() as T
        }
    } catch (ex) {
        return {
            ok: false,
            data: ex instanceof Error ? ex.message : 'Fetch error'
        }
    }
}

async function get<T>(path: string, args: RequestInit = { method: 'get' }): Promise<SuccessfulResponse<T> | ErrorResponse> {
    return await http<T>(path, args)
}

async function post<T>(
    path: string,
    body: any,
    args: RequestInit = { method: 'post', body: JSON.stringify(body) }
): Promise<SuccessfulResponse<T> | ErrorResponse> {
    return await http<T>(path, args)
}

async function put<T>(
    path: string,
    body: any,
    args: RequestInit = { method: 'put', body: JSON.stringify(body) }
): Promise<SuccessfulResponse<T> | ErrorResponse> {
    return await http<T>(path, args)
}

async function del<T>(path: string, args: RequestInit = { method: 'delete' }): Promise<SuccessfulResponse<T> | ErrorResponse> {
    return await http<T>(path, args)
}

export default {
    Test: {
        secret: () => get<string>('/test/secret'),
    },
    User: {
        getAll: () => get<User[]>('/user'),
        get: (id: string) => get<User>('/user/' + id),
        add: (user: UserCreate) => post<Res<User>>('/user', user),
        update: (user: UserEdit) => put<Res<User>>('/user', user),
        delete: (id: string) => del<Res>('/user/' + id),
    },
    Role: {
        getAll: () => get<Role[]>('/role'),
        get: (id: string) => get<Role>('/role/' + id),
        add: (role: Role) => post<Res<Role>>('/role', role),
        update: (role: Role) => put<Res<Role>>('/role', role),
        delete: (id: string) => del<Res>('/role/' + id),
    },
    ApiResource: {
        getAll: () => get<ApiResource[]>('/apiresource'),
        get: (id: number) => get<ApiResource>('/apiresource/' + id),
        add: (apiResource: ApiResourceModel) => post<Res<ApiResource>>('/apiresource', apiResource),
        update: (apiResource: ApiResourceModel) => put<Res<ApiResource>>('/apiresource', apiResource),
        delete: (id: number) => del<Res>('/apiresource/' + id),
    },
    Client: {
        getAll: () => get<Client[]>('/client'),
        get: (id: number) => get<Client>('/client/' + id),
        add: (client: ClientEdit) => post<Res<Client>>('/client', client),
        update: (client: ClientEdit) => post<Res<Client>>('/client/update', client),
        delete: (id: number) => del<Res>('/client/' + id),
    },
}
