import { RootState } from 'app/store'
import { push } from 'connected-react-router'
import { ApiResource, ApiResourceModel } from 'models/ApiResource'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import agent from '../../agent'
import ApiResourceForm from './ApiResourceForm'
import { editApiResource } from './apiResourceSlice'

export default function ApiResourceEdit() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const apiResource = useSelector((state: RootState) => {
        return state.apiResource.apiResources.find(a => a.id === Number(id))
    }) as ApiResourceModel

    const afterSubmit = (apiResource: ApiResource) => {
        dispatch(editApiResource(apiResource))
        dispatch(push('/api-resource'))
    }

    return apiResource ? (
        <ApiResourceForm value={apiResource} afterSubmit={afterSubmit} formRequest={agent.ApiResource.update} />
    ) : (
        <p>No ApiResource</p>
    )
}
